import React from 'react'
import Styles from './benefits.module.scss'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from 'classnames/bind'

let cx = classNames.bind(Styles);

const Benefits = () => {
    const data = useStaticQuery(graphql`
    query {
      timeZone: file(relativePath: { eq: "Time Zone.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flexibility: file(relativePath: { eq: "Flexibility.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allTech: file(relativePath: { eq: "All Tech.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
    <div className={cx(`fullWrap`,`left`)}>
      <div className={Styles.tile}>
        <div className={Styles.image}>
        <Img fluid={data.timeZone.childImageSharp.fluid} />
        </div>
        <div className={Styles.text}>
          <h4>Seamless and beautiful &mdash; Always.</h4>
          <p>Reprehenderit eu commodo pariatur et velit do elit magna cillum.</p>
        </div>
      </div>
    </div>
    <div className={cx(`fullWrap`,`center`)}>
      <div className={Styles.tile}>
        <div className={Styles.image}>
        <Img fluid={data.flexibility.childImageSharp.fluid} />
        </div>
        <div className={Styles.text}>
          <h4>No timezones. No limitations.</h4>
          <p>Reprehenderit eu commodo pariatur et velit do elit magna cillum.</p>
        </div>
      </div>
    </div>
    <div className={cx(`fullWrap`,`right`)}>
      <div className={Styles.tile}>
        <div className={Styles.image}>
          <Img fluid={data.allTech.childImageSharp.fluid} />
        </div>
        <div className={Styles.text}>
          <h4>Seems impossible? We've got you covered.</h4>
          <p>Reprehenderit eu commodo pariatur et velit do elit magna cillum.</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Benefits