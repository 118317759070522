import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Button from '../components/button/button'
import Testimonial from '../components/testimonial/testimonial'
import Services from '../components/services/services'
import Benefits from "../components/benefits/benefits"
import SEO from "../components/seo"

import image from '../images/services.jpg'
import { motion } from "framer-motion"


const ServicesPage = () => {
  const transition = {
    type: "spring",
    mass: 0.35,
    stiffness: 75,
    duration: 0.3
  };

  return (
  <Layout>
    <SEO title="Services" />
    <section id={`cover`} className={`primary header-fix lg`} style={{
        backgroundImage: `url(${image})`,
        backgroundSize: `cover`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        boxSizing: `border-box`
      }}>
      <div className={`content--md`}>
        <motion.h3 className={`center thin`} initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={transition}>Our Services</motion.h3>
        <motion.h1 className={`center`} initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={transition}>Please take a seat, let's discuss how we can assist you</motion.h1>
      </div>
    </section>
    <section className={``}>
      <div className={`content`}>
        <h2 className={`center`}>What we do</h2>
        <Services />
      </div>
    </section>
    <section className={`grey`}>
      <div className={`content`}>
        <h2 className={`center margin-bottom`}>Why Creavo?</h2>
        <Benefits />
        <div className={`center-group margins-lg`}><Link to="/connect"><Button variant={`filled`}>Work with us</Button></Link></div>
      </div>
    </section>
  </Layout>
)}

export default ServicesPage
